import { lazy } from 'react';
import { Module } from '../core/types';
import { Widgets } from '@mui/icons-material';

// ############### props from routes
import { dependencies as MovimentosDep } from '../pages/Estoque/Movimentos/route.props';

// ############### Elements of Routes

const Movimentos = lazy(() => import('../pages/Estoque/Movimentos'));
const Grupos = lazy(() => import('../pages/Producao/Grupos'));

// ############### Routes

export const US_Production: Module = {
    name: 'production',
    routes: [
        {
            name: 'production', icon: <Widgets />, internalName: 'producao',
            children: [
                { name: 'productions', element: <Movimentos key="producoes" grupo='producao' path='producoes' />, resource: 'movimentosestoque', dependencies: MovimentosDep, internalGroup: 'producao', internalName: 'producoes' },
                { name: 'groups', element: <Grupos />, resource: 'gruposproducao', internalGroup: 'producao', internalName: 'grupos' },
            ]
        },
    ]
}

