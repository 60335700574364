import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../../services/Theme/types';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        BoxContent: {
            minHeight: '100%',
            padding: 20
        },
        TitleButton: {
            marginLeft: 12,
            minWidth: 32,
            // padding: '8px 12px 8px 12px',
        },
        Icon: {
            padding: 0,
            margin: 0
        },
        Button: {
            fontSize: '0.9rem',
            color: theme.palette.primary.contrastText,
        },
        rootRedButton: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.getContrastText(theme.palette.error.dark),
            border: '0.5px solid ' + theme.others.negativeValue,
            '&:hover': {
                backgroundColor: theme.palette.error.light,
            },
            '&:disabled': {
                color: theme.palette.getContrastText(theme.others.negativeBackground) + ' !important',
                backgroundColor: theme.others.negativeBackground,
                border: '0.5px solid ' + theme.others.negativeValue,
            }
        },

        rootGreenButton: {
            backgroundColor: theme.others.greenButton,
            color: theme.palette.getContrastText(theme.palette.error.dark),
            border: '0.5px solid ' + theme.others.positiveValue,
            '&:hover': {
                backgroundColor: theme.others.greenButtonHover,
            },
            '&:disabled': {
                backgroundColor: theme.others.greenButtonDisabled,
            }
        },

        defaultButton: {

        }


    }),
);

export default useStyles;