import { Button, Hidden, PropTypes, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

type NewColor = 'red' | 'green';

type ButtonProps = {
    type?: "button" | "reset" | "submit" | undefined;
    label?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    icon?: React.ReactNode;
    color?: PropTypes.Color | NewColor;
    shrink?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    disabled?: boolean;
    className?: string;
    size?: 'small' | 'medium' | 'large'
}

const ColorButton: React.FC<ButtonProps> = ({ label, type, onClick, icon, color, shrink, variant, disabled, children, className, size }) => {
    const classes = useStyles();
    let rootClass;
    switch (color) {
        case 'red':
            rootClass = classes.rootRedButton;
            break;

        case 'green':
            rootClass = classes.rootGreenButton;
            break;

        case undefined:
        case 'default':
            rootClass = classes.defaultButton;
    }
    const _color = (color !== 'red' && color !== 'green') ? (color as PropTypes.Color) : undefined;

    const getChildren = () => {
        if (label)
            return (<Typography className={classes.Button}>{label}</Typography>)
        return children;
    }

    return shrink ?
        (<>
            <Hidden smDown={true}>
                <Button
                    type={type}
                    classes={{ root: rootClass }}
                    variant={variant || "contained"}
                    color={_color}
                    size={size}
                    className={'BGColorButton ' + classes.TitleButton + ' ' + className}
                    startIcon={!!children || !!label ? icon : undefined}
                    disabled={disabled}
                    onClick={onClick}>
                    {!!children || !!label ? getChildren() : icon}
                </Button>
            </Hidden>
            <Hidden mdUp={true}>
                <Button
                    type={type}
                    classes={{ root: rootClass }}
                    variant={variant || "contained"}
                    color={_color}
                    size={size}
                    className={'BGColorButton ' + classes.TitleButton + ' ' + className}
                    disabled={disabled}
                    onClick={onClick}>
                    {icon}
                </Button>
            </Hidden>
        </>)
        :

        <Button
            type={type}
            classes={{ root: rootClass }}
            variant={variant || "contained"}
            color={_color}
            size={size}
            className={'BGColorButton ' + classes.TitleButton + ' ' + className}
            startIcon={!!children || !!label ? icon : undefined}
            disabled={disabled}
            onClick={onClick}>
            {!!children || !!label ? getChildren() : icon}
        </Button>;
}

export default ColorButton;
