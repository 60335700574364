import { createTheme } from '@material-ui/core';
import { CustomTheme } from '../../core/services/Theme/types';
import { ReactComponent as LogoSvg } from './logo.svg';

export const Logo = <LogoSvg />;

const base = createTheme({
    palette: {
        type: 'dark',
        background: {
            default: 'hsl(210, 14%, 7%)',
            paper: 'hsl(210, 14%, 7%)',

        },
        primary: {
            contrastText: 'rgba(255, 255, 255, 1)',
            main: 'hsl(210, 100%, 60%)',
        },
        secondary: {
            contrastText: 'rgba(255, 255, 255, 1)',
            main: 'hsl(210, 100%, 60%)',
            dark: 'hsl(210, 100%, 30%)'
        },
        text: {
            primary: '#ccc',
            secondary: 'rgba(255, 255, 255, 0.6)',
        },
        error: {
            main: '#F44336',
            dark: 'rgba(244, 67, 54, 0.3)',
            light: 'rgba(244, 67, 54, 0.5)',
        },
        divider: 'hsl(210, 14%, 50%)',
        action :{
            selected: 'hsla(210, 100%, 30%, 0.2)',
            hover: 'hsla(210, 100%, 30%, 0.2)',
            disabledBackground: 'rgba(0, 0, 0, 0.1)',
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                padding: '6px 12px 6px 12px',
                backgroundColor: 'rgb(144, 202, 249)',
                color: 'rgba(0, 0, 0, 0.87)',
                '&:hover': {
                    backgroundColor: 'rgb(66, 165, 245)'
                }
            },
            containedPrimary: {
                padding: '6px 12px 6px 12px',
                backgroundColor: 'hsl(210, 100%, 23%)',
                border: '1px solid hsl(210, 100%, 42%)',
                '&:hover': {
                    backgroundColor: 'hsl(210, 100%, 30%)'
                },

                '&:disabled': {
                    border: '1px solid hsla(210, 100%, 30%, 0.2)',
                }
            },

            containedSecondary: {
                padding: '6px 12px 6px 12px',
                backgroundColor: 'hsl(210, 100%, 23%)',
                border: '1px solid hsl(210, 100%, 42%)',
                '&:hover': {
                    backgroundColor: 'hsl(210, 100%, 30%)'
                }
            },
            outlined: {
                padding: '6px 12px 6px 12px',
            }
        },
        MuiTableRow: {

            root: {
                border: '0.5px solid hsl(210, 14%, 13%)',
                '&.MuiTableRow-hover:hover' :{
                    backgroundColor: 'hsla(210, 14%, 7%, 0.6) !important',

                }
            }
        },

        MuiPaper: {
            root: {
                border: "1px solid",
                borderColor: "hsl(210, 14%, 13%)",
                background: "linear-gradient(to bottom right, hsla(210, 100%, 23%, 0.1) 25%, hsla(210, 14%, 9%, 0.2) 100%)",

            },
        },
        MuiCard: {
            root: {
                borderRadius: '12px'
            }
        },
        MuiBackdrop: {
            root: {
                "backdrop-filter": 'blur(2px)',
            }
        },
        MuiDrawer: {
            paperAnchorLeft: {
                width: 256
            },
            paperAnchorDockedLeft: {
                borderRight: 'none',
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 0,
                marginRight: 8
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '0.85rem',
                '& input:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 30px hsl(210, 14%, 7%) inset !important',
                }
            }
        },
        MuiInputLabel: {
            root: {
                fontSize: '0.85rem'
            },
            shrink: {
                fontSize: '1rem'
            }

        },
        MuiOutlinedInput: {
            notchedOutline: {
                fontSize: '1rem'
            }
        },
        MuiAccordionDetails: {
            root: {
                fontSize: '0.85rem'
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: '0.85rem'
            }
        },
    },


    typography: {
        //fontFamily: 'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        h1: {
            fontWeight: 500,
            fontSize: 35,
            letterSpacing: '-0.24px'
        },
        h2: {
            fontWeight: 500,
            fontSize: 29,
            letterSpacing: '-0.24px'
        },
        h3: {
            fontWeight: 500,
            fontSize: 24,
            letterSpacing: '-0.06px'
        },
        h4: {
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '-0.06px'
        },
        h5: {
            fontWeight: 500,
            fontSize: 16,
            letterSpacing: '-0.05px'
        },
        h6: {
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: '-0.05px'
        },
        overline: {
            fontWeight: 500
        },
    }
});

const theme: CustomTheme = {
    logo: {
        backgroundColor: 'hsl(210, 14%, 7%)',
        color: 'rgb(56, 142, 60)',
        tag: {
            backgroundColor: '#4782da',
            textColor: 'rgba(255, 255, 255, 0.85)',
        }
    },
    SideBar: {
        background: 'hsl(210, 14%, 7%)',
        subItemsBackgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderColor: 'hsl(210, 14%, 13%)',
        hoverBackground: 'hsla(210, 100%, 23%, 0.1)',
        hoverBorder: 'hsla(210, 100%, 30%, 0.4)',
        selectedBackground: 'hsla(210, 100%, 30%, 0.2)',
        selectedBorder: 'hsl(210, 100%, 38%)',
    },
    navbar: {
        backgroundColor: 'hsl(210, 14%, 7%)',
        textColor: 'rgba(255, 255, 255, 0.85)',
    },
    others: {
        iconColor: '#aaa',
        tagIconColor: '#555',
        borderColor: 'hsl(210, 14%, 13%)',
        positiveValue: '#64DD17',
        positiveBackground: 'rgb(0, 255, 0, 0.05)',
        negativeValue: '#f44336',
        negativeBackground: 'rgb(244, 67, 54, 0.05)',
        greenButton: 'rgb(0, 255, 0, 0.2)',
        greenButtonHover: '#388E3C',
        greenButtonDisabled: 'rgb(0, 255, 0, 0.1)',
        green: '#388E3C',
        darkGreen: '#1B5E20',
        lightGreen: '#81C784',
        blue: '#2196F3',
        red: '#F44336',
        darkRed: 'hsl(0.00,73.46%,20%)',
        organge: '#FFA000',
        purple: 'rgba(119, 93, 208, 0.85)',
        gray: '#616161',
        secondaryHightlightColor: '#2b4d80',
        hoverColor: 'hsla(210, 14%, 7%, 0.6)',
    },
    ...base
};

export default theme;